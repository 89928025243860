
import { defineComponent } from "vue";
import { mapActions, mapGetters } from "vuex";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import Calendar from "primevue/calendar";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import CheckBox from "primevue/checkbox";
import DropDown from "primevue/dropdown";
import Customer, { group, RepItem } from "@/types/customer";
import ShipOption from "@/types/shipoption";
import PriceCode from "@/types/pricingcode";
import CurrencyCode from "@/types/currency";
import TextArea from "primevue/textarea";
import Card from "primevue/card";
import Dialog from "primevue/dialog";
import CollapsibleSection from "@/components/UI/CollapsibleSection.vue";
import Utils from "@/utility/utils";

import ConfirmChangesDialog from "@/components/ConfirmChangesDialog.vue";
import { Field, isFieldReadOnly } from "@/types/fdict";
import { AccessRights } from "@/types/accessControl/AbstractAccessGetter";
import RoverInput from "../UI/RoverInput.vue";
export default defineComponent({
  props: {
    index: Number,
  },
  name: "General",
  components: {
    InputText,
    Button,
    Calendar,
    DataTable,
    Column,
    CheckBox,
    DropDown,
    TextArea,
    Card,
    Dialog,
    ConfirmChangesDialog,
    CollapsibleSection,
    RoverInput,
  },
  computed: {
    ...mapGetters({
      getShipVia: "customerInquiry/getShipVia",
      getPricingCodes: "customerInquiry/getPricingCodes",
      getCurrency: "customerInquiry/getCurrency",
      getTerms: "terms/getTerms",
      getGroups: "customerInquiry/getGroups",
      getCustomer: "customerInquiry/getCustomer",
      getContracts: "customerInquiry/getContracts",
      getClient: "session/getClient",
      getField: "fdict/getField",
      getAccessRights: "session/getAccessRights",
    }),
    getCustomerAccessRights(): AccessRights {
      return this.getAccessRights("/customers");
    },
    activeCustomer(): any {
      return this.getCustomer(this.index);
    },
    shipVia() {
      const shipOptions = this.getShipVia as Array<ShipOption>;
      let map = null;
      if (shipOptions) {
        map = shipOptions.map((item) => {
          return item.ship_via;
        });
      }

      return map;
    },
    priceCodes() {
      const priceCodes = this.getPricingCodes as Array<PriceCode>;
      let map = null;
      if (priceCodes) {
        map = priceCodes.map((item) => {
          return item.code;
        });
      }
      return map;
    },
    freightList(): Array<string> {
      const list = Object.values(this.freight);
      return list;
    },
    currencyList() {
      const list = this.getCurrency as Array<CurrencyCode>;
      const options =
        list != null ? list.map((item) => item.currency_code) : [];
      return options;
    },
    groupList() {
      const list = this.getGroups as Array<group>;
      const options = list?.map((item) => item.description);
      return options;
    },
    notesIconClass(): string {
      return this.isNoteIconDown
        ? "pi pi-chevron-down ml-2"
        : "pi pi-chevron-right ml-2";
    },
    contactsIconClass(): string {
      return this.isContactIconDown
        ? "pi pi-chevron-down ml-2"
        : "pi pi-chevron-right ml-2";
    },
    creditIconClass(): string {
      return this.isCreditIconDown
        ? "pi pi-chevron-down ml-2"
        : "pi pi-chevron-right ml-2";
    },
    arIconClass(): string {
      return this.isARIconDown
        ? "pi pi-chevron-down ml-2"
        : "pi pi-chevron-right ml-2";
    },
    creditWarning() {
      let limit_exceeded = false;
      let limit_amt = 0;
      let credit_hold = this.customer.credit_hold === "Y";
      if (!this.customer.credit_limit) {
        limit_amt = parseFloat(this.customer.credit_limit || "") * 100;
        const ar_bal = parseFloat(this.customer.ar_bal || "") || 0.0;
        const open_order_bal =
          parseFloat(this.customer.open_order_bal || "") || 0.0;
        limit_exceeded = limit_amt - (ar_bal + open_order_bal) < 0;
      }

      if (limit_exceeded && credit_hold) {
        return "*** CREDIT LIMIT EXCEEDED. CUSTOMER IS ON CREDIT HOLD ***";
      } else if (limit_exceeded) {
        return "*** CREDIT LIMIT EXCEEDED ***";
      } else if (credit_hold) {
        return "*** CUSTOMER IS ON CREDIT HOLD ***";
      } else {
        return "";
      }
    },
    termsCodeField(): Field {
      return this.getField("CUST", "TERMS");
    },
    creditLimitField(): Field {
      return this.getField("CUST", "CREDIT.LIMIT");
    },
    pastDueDaysField(): Field {
      return this.getField("CUST", "PAST.DUE.DAYS");
    },
    bankNoField(): Field {
      return this.getField("CUST", "BANK.NO");
    },
  },
  created() {
    this.createOrUpdateCustomer();
    this.openTabSections();
  },
  data() {
    return {
      customer: {} as Customer,
      freight: {
        P: "Prepaid",
        C: "Collect",
        F: "Freight Free",
        T: "Third Party",
      },
      freightCode: "",
      changes: {} as Customer,
      groups: [] as Array<group>,
      deleteRepDialog: false,
      deleteCountryDialog: false,
      deleteGroupDialog: false,
      rep: {} as RepItem,
      marketing: false,
      editRepDialog: false,
      loading: false,
      isValidating: false,
      activeIndexes: [0, 1, 2],
      isNoteIconDown: false,
      isContactIconDown: false,
      isCreditIconDown: false,
      isARIconDown: false,
    };
  },
  methods: {
    ...mapActions({
      fetchContracts: "customerInquiry/getContracts",
      fetchTerms: "customerInquiry/getTerms",
      validateCustomerAddress: "customerInquiry/validateCustomerAddress",
      updateCustomer: "customerInquiry/updateCustomer",
      updateField: "customerInquiry/updateField",
    }),
    createOrUpdateCustomer() {
      if (this.activeCustomer) {
        this.customer = this.activeCustomer as Customer;
        if (this.customer.notes) {
          this.customer.notes = this.customer.notes?.replaceAll("^", "\n");
        }

        if (this.customer.address_items.length === 0) {
          this.customer.address_items.push({ address: "" });
        }

        this.freightCode = this.customer.freight_code || "";
      }
      if (this.getContracts(this.index) == null) {
        this.fetchContracts({
          id: this.customer.cust_id,
          client: this.getClient,
          index: this.index,
        });
      }
      if (this.getTerms?.length <= 0 || this.getTerms == null) {
        this.fetchTerms({
          client: this.getClient,
        });
      }
    },
    openTabSections() {
      this.isNoteIconDown = true;
      this.isCreditIconDown = true;
      this.isContactIconDown = true;
      this.isARIconDown = true;
    },
    toggleIcon(tab: string) {
      switch (tab) {
        case "Notes":
          this.isNoteIconDown = !this.isNoteIconDown;
          break;
        case "Credit":
          this.isCreditIconDown = !this.isCreditIconDown;
          break;
        case "Contacts":
          this.isContactIconDown = !this.isContactIconDown;
          break;
        case "AR":
          this.isARIconDown = !this.isARIconDown;
          break;
        default:
          tab;
      }
    },
    freightChange(event: any) {
      const val = event.value as string;
      if (this.customer != null) {
        this.customer.freight_code = val.charAt(0);
      }
    },
    changeGroup(event: any, index: number) {
      const val = event.value as group;
      if (this.customer != null && this.customer.group_code_items != null) {
        this.customer.group_code_items[index].group_code = val.group_code;
      }
    },
    toggleGroupMenu(event: any) {
      (this.$refs.menu as any).toggle(event);
    },
    confirmDeleteRep(rep: RepItem) {
      this.rep = rep;
      this.deleteRepDialog = true;
    },
    deleteRep() {
      const newList = this.customer.rep_items.filter(
        (item) => item.rep != this.rep.rep,
      );
      this.customer.rep_items = newList;
      this.deleteRepDialog = false;
    },
    editRep(rep: RepItem) {
      this.rep = { ...rep };
      this.editRepDialog = true;
    },
    saveRep() {
      const repList = this.customer.rep_items;
      let foundIndex = -1;
      const found = repList.find((item, index) => {
        if (item.rep == this.rep.rep) {
          foundIndex = index;
        }
        return item.rep == this.rep.rep;
      });

      if (found) {
        found.rep = this.rep.rep;
        found.order_pct = this.rep.order_pct;
      } else {
        this.customer.rep_items.push(this.rep);
      }
      this.editRepDialog = false;
    },
    closeEditModal() {
      this.editRepDialog = false;
    },
    newRep() {
      this.rep = {} as RepItem;
      this.editRepDialog = true;
    },
    setArrayFields(record: any) {
      record.quicklist_name_items = null;
      record.attachments_items = null;
      record.ship_seq_items = null;
      record.rebate_type_items = null;
      record.credit_card_no_items = null;
      record.from_sales_amt_items = null;
      record.change_date_items = null;
      record.contact_id_items = null;
    },
    validateAddress(address: any) {
      this.isValidating = true;
      this.validateCustomerAddress(address)
        .then(() => {
          this.isValidating = false;
        })
        .catch(() => {
          this.isValidating = false;
        });
    },
    formatDate(event: any) {
      this.customer.date = Utils.formatDate(this.customer.date as string);
    },
    formatInactiveDate(event: any) {
      if (this.customer.inactive_date) {
        this.customer.inactive_date = Utils.formatDate(
          this.customer.inactive_date as string,
        );
      } else {
        this.customer.inactive_date = "";
      }
    },
    addAddressLine(index: number) {
      this.customer.address_items.splice(index + 1, 0, { address: "" });
    },
    deleteAddressLine(index: number) {
      this.customer.address_items.splice(index, 1);
      if (this.customer.address_items.length == 0) {
        this.customer.address_items.push({ address: "" });
      }
    },
    isFieldReadOnly(field: Field) {
      return isFieldReadOnly(field, this.getCustomerAccessRights);
    },
  },
  watch: {
    activeCustomer: {
      handler(val) {
        this.createOrUpdateCustomer();
      },
      deep: true,
    },
  },
});
